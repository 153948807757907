<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2
          class="mb-1 text-primary"
          style="font-weight: bold;"
        >
          Hello!
        </h2>
        <p class="mb-2">
          This page is not ready. We're coming soon.
          <br>
          Stay tune.
        </p>
        <b-img
          fluid
          :src="imgUrl"
          alt="stay-tune"
        />
        <div class="mt-5">
          <b-img
            fluid
            :src="logo"
            alt="logo"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg } from 'bootstrap-vue'
// import storage from '@/helpers/storage'
// {{ $store.state.user.userLoggedIn.nama_panggilan }
export default {
  components: {
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/stay-tune.svg'),
      logo: require('@/assets/images/logo/uiconnect.png'),
      msg: '',
    }
  },
  computed: {
    imgUrl() {
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
